import * as React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout/layout"
import Seo from "../../components/seo"

import "../index.scss"

import ImgLogo from "../../images/logo-tanecni-zlonin.svg"

import "../normalize.css"

import Bloglist from "../../components/bloglist/bloglist"
import Footer from "../../components/footer/footer"

const Article = () => {
	return (

		<Layout>
			<Seo title="Pomoc Ukrajině" />

			<div className="container container--article mb3">

				<Link to="/" data-sal="fade" data-sal-duration="1000">
					<img src={ImgLogo} alt="Taneční Zlonín logo" className="tz-miniheader__logo" />
				</Link>

				<h1 className="tz-miniheader__h1" data-sal="fade" data-sal-duration="1000">
					Pomoc Ukrajině
				</h1>

				<p data-sal="fade" data-sal-duration="1000">Vstupné nedělní tančírny darujeme na&nbsp;veřejnou sbírku "Pomoc Ukrajině" zřízenou obcí Zlonín! Přijďte si v&nbsp;neděli 6.&nbsp;března od&nbsp;19:15 zatančit 💃🕺</p>

				<ul data-sal="fade" data-sal-duration="1000">
					<li>➡️ Základy tanců Vám na&nbsp;tančírně ukážeme </li>
					<li>➡️ Tančírna je veřejná a&nbsp;je pro&nbsp;všechny milovníky tance&nbsp;❤</li>
					<li>➡️ Kde: velký sál KC&nbsp;Zlonín </li>
					<li>🍷 Občerstvení si přineste s&nbsp;sebou</li>
				</ul>

				<p data-sal="fade" data-sal-duration="1000">V případě dotazů nás kontaktujte:</p>
				<ul data-sal="fade" data-sal-duration="1000">
					<li>na emailu <a href="mailto:tanecni.zlonin@gmail.com">tanecni.zlonin@gmail.com</a></li>
					<li>na messengeru stránky <a href="https://www.facebook.com/tanecni.zlonin" target="_blank" rel="noreferrer">Taneční&nbsp;Zlonín</a></li>
					<li>na telefonu/WhatsApp <a href="tel:+420732560838">+420 732 560 838</a></li>
				</ul>

				<p data-sal="fade" data-sal-duration="1000">Těšíme se na Vás v neděli!<br />Irina &amp; Jarda</p>

				<Link to="/nedelni-tancirna" data-sal="fade" data-sal-duration="1000" className="tz-button tz-button--small tz-button--inverse">
					Více informací o&nbsp;nedělní tančnírně&nbsp;zde
				</Link>

			</div>

			<Bloglist></Bloglist>
			<Footer></Footer>

		</Layout>

	)
}

export default Article